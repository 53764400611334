.App > div:nth-child(4n+1) .cardContent {
  background-color: #bbdefb;
}
.App > div:nth-child(4n+1) .MuiTextField-root fieldset {
  border-color: #bbdefb;
}
.App > div:nth-child(4n+1) .Mui-focused {
  color: currentColor;
}
.App > div:nth-child(4n+1) .Mui-focused .MuiOutlinedInput-notchedOutline,
.App > div:nth-child(4n+1) .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #90caf9;
}
.App > div:nth-child(4n+1) .textField .MuiInput-multiline {
  background: #e3f2fd;
}

.App > div:nth-child(4n+2) .cardContent {
  background-color: #c8e6c9;
}
.App > div:nth-child(4n+2) .MuiTextField-root fieldset {
  border-color: #c8e6c9;
}
.App > div:nth-child(4n+2) .Mui-focused {
  color: currentColor;
}
.App > div:nth-child(4n+2) .Mui-focused .MuiOutlinedInput-notchedOutline,
.App > div:nth-child(4n+2) .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #a5d6a7;
}
.App > div:nth-child(4n+2) .textField .MuiInput-multiline {
  background: #e8f5e9;
}

.App > div:nth-child(4n+3) .cardContent {
  background-color: #ffe0b2;
}
.App > div:nth-child(4n+3) .MuiTextField-root fieldset {
  border-color: #ffe0b2;
}
.App > div:nth-child(4n+3) .Mui-focused {
  color: currentColor;
}
.App > div:nth-child(4n+3) .Mui-focused .MuiOutlinedInput-notchedOutline,
.App > div:nth-child(4n+3) .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #ffcc80;
}
.App > div:nth-child(4n+3) .textField .MuiInput-multiline {
  background: #fff3e0;
}

.App > div:nth-child(4n+4) .cardContent {
  background-color: #e1bee7;
}
.App > div:nth-child(4n+4) .MuiTextField-root fieldset {
  border-color: #e1bee7;
}
.App > div:nth-child(4n+4) .Mui-focused {
  color: currentColor;
}
.App > div:nth-child(4n+4) .Mui-focused .MuiOutlinedInput-notchedOutline,
.App > div:nth-child(4n+4) .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #ce93d8;
}
.App > div:nth-child(4n+4) .textField .MuiInput-multiline {
  background: #f3e5f5;
}
