.textField .MuiInput-multiline {
  padding: 0;
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  /* font-size: 14px; */
  letter-spacing: 0.01071em !important;
}

.textField .MuiInput-multiline::before,
.textField .MuiInput-multiline::after {
  content: none;
}

.cardContent .MuiTypography-root {
  /* font-size: 14px; */
  white-space: pre-line;
}

/* .textField.focus textarea {
  letter-spacing: 0.14994px;
} */
