.App {
  display: flex;
  min-height: calc(100vh - 56px);
  overflow-x: scroll;
  padding-left: 0;
  padding-top: 56px;
}

@media (min-width: 600px) {
  .App {
    min-height: calc(100vh - 64px);
    padding-top: 64px;
  }
}

@media (min-width: 1280px) {
  .App {
    padding-left: 250px;
  }
}

.formInput {
  margin-bottom: 16px;
}

.container {
  display: flex;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 200px;
  padding: 8px;
}

.column:first-of-type {
  padding-left: 16px;
}

.column:last-of-type {
  padding-right: 16px;
}

[data-rbd-droppable-id] {
  flex: 1;
}

.c {
  margin-bottom :16px;
}

.cardContent {
  position: relative;
  word-break: break-word;
}

[contenteditable="true"].single-line {
  white-space: nowrap;
  overflow: hidden;
}

[contenteditable="true"].single-line br {
  display: none;
}

[contenteditable="true"].single-line * {
  display: inline;
  white-space: nowrap;
}

.form {
  /* width: 100vh; */
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 150px;
  min-height: 100vh;
}

.form > .MuiFormControl-root,
.form > .MuiButtonBase-root {
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 300px;
  width: 100%;
}

.clickable {
  cursor: pointer;
}
